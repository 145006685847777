<template>
  <div class="onboarding container h-100">
    <div
      class="d-flex align-items-center justify-content-center flex-column h-100"
    >
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "Onboarding",
  mounted() {
    this.$route.params.onboarding = true;
  },
};
</script>
